@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;

  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

.contentNumberlist{
  list-style-type: decimal;
  list-style-position: inside;
  margin: 0;
}

.contentNumberlist li{
  @apply --marketplaceH4FontStyles;
}

.contentTable{
  margin-top: 16px;
  margin-bottom: 14px;
  border: 1px solid var(--matterColor);
}

.contentTable th,
.contentTable td{
  padding: 8px;
  border-top: 1px solid var(--matterColor);
  border-bottom: 1px solid var(--matterColor);
}

.contentTable th:first-of-type{
  border-right: 1px solid var(--matterColor);
}

.contentTable td:first-of-type{
  background-color: var(--matterColorNegative);
  border-right: 1px solid var(--matterColor);
}

.attentionlist{
  padding-left: 16px;
}
.attentionlist p{
  margin-top: 8px;
  margin-bottom: 8px
}