@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: end;
  overflow-x: none;
}

.root::after{
  content: '';
  display: block;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: var(--matterColorDark);
}

.darkSkin {
  background-color: var(--matterColor);
}

.tab {
  width: 100%;
  text-align: center;
  margin-left: 0;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  /* @media (--viewportLarge) {
    margin-left: 24px;
  } */
}

.tabContent::after {
  content:'';
  position: relative;
  bottom: -6px;
  left: -8px;
  width: 118%;
  height: 2px;
  background-color: var(--matterColorDark);

  @media (--viewportMedium) {
    left: -12px;
    width: 125%;
  }
}

.tabContent {
  @apply --marketplaceLinkStyles;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border-bottom: none; */

  /* Font */
  @apply --marketplaceTabNavHorizontalFontStyles;

  color: var(--matterColorAnti);
  background-color: var(--matterColorNegative);
  padding: 4px;

  border-width: 2px;
  border-style: solid;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    padding: 8px;
    border-width: 2px;
    padding: 4px 10px 4px 10px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-color: var(--matterColorDark);
  border-bottom: none;
  color: var(--matterColorDark);
  background-color: var(--matterColorLight);
  padding-bottom: 0;
  padding-top: 6px;
}

.selectedTabContent::after {
  content: '';
  display: none;
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  background-color: var(--matterColorAnti);
  border-bottom: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--matterColorAnti);
  background-color: var(--matterColor);
  border:none;
  width: max-content;
  justify-content: center;

  &:hover {
    color: #c5950c;
  }
}

.tabContentDarkSkin span{
  font-weight: 600;
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorDark);
  background-color: var(--matterColorAnti);
  border-bottom:4px solid var(--matterColorDark);
  border-radius: 0;
}

.tabContentDarkSkin::after {
  content:'';
  background-color: transparent;
}

.disabledDarkSkin {
  color: var(--matterColorDark);
  opacity: 0.3;
}
